import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { NameValue } from '../../shared/models/dto/general';
import { ITSSensor } from '../../shared/models/dto/sensor';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';

export enum FieldType {
    Number,
    Operator
}

interface Field {
    type: FieldType,
    value: number | string
}

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class SensorFormulaDialogComponent extends AuthComponentBase {
    @Prop({ default: false }) readonly show: boolean;
    @Prop({ default: null }) readonly sensorId: number;
    @Prop({ default: null }) readonly deviceId: number;
    @Prop({ default: '' }) readonly sensorName: string;

    errors: NameValue[] = [];
    refs = this.$refs as any;

    number = FieldType.Number;
    operator = FieldType.Operator;
    valid: boolean = false;

    operators: string[] = [
        "+",
        "-",
        "*",
        "/",
        "%"
    ];

    initialValues: ITSSensor = {
        iTSDeviceId: null,
        sensorId: null,
        displayName: null,
        formula: null,
        iTSDeviceName: '',
        sensorDisplayName: ''
    };

    itsSensor: ITSSensor;

    fields: Field[] = [];
    formTitle: string = '';

    @Watch('show', { immediate: true })
    onShowChanged() {
        if (this.show) {
            this.fields = [];
            this.itsSensor = this.initialValues;

            this.errors = [];
            this.formTitle = this.sensorName;
            
            if (this.deviceId && this.sensorId) {
                this.authService.get<IRestResponseDto<ITSSensor>>(`/api/itssensors/${this.deviceId}/${this.sensorId}`).then((response) => {
                    this.itsSensor = <ITSSensor><unknown>response.content;

                    if (this.itsSensor.formula.length) {
                        this.fields = this.itsSensor.formula.split(' ').map(x => {
                            let type = FieldType.Number;
                            if (this.operators.includes(x)) {
                                type = FieldType.Operator;
                            }
                            return {
                                type: type,
                                value: x
                            };
                        });
                    } 
                });
            }
        }
        else {
            this.fields = [];
            this.itsSensor = this.initialValues;
        }
    }

    onFieldChanged() {
        if (this.fields.length > 0) {
            this.itsSensor.formula = this.fields.map(x => { return x.value }).join(' ');
        }
    }

    close() {
        this.$emit('completed', false);
    }

    addField() {
        if (this.fields.length === 0) {
            this.fields.push({
                type: FieldType.Operator,
                value: '+'
            });
        }
        else {
            if (this.fields[this.fields.length - 1].type === FieldType.Number) {
                this.fields.push({
                    type: FieldType.Operator,
                    value: '+'
                });
            }
            else {
                this.fields.push({
                    type: FieldType.Number,
                    value: 0
                });
            }
        }

        this.onFieldChanged();
    }

    removeField() {
        this.fields.pop();
        this.onFieldChanged();
    }

    removeSensorFormula() {
        this.itsSensor.formula = '';

        const itsSensor: ITSSensor = {
            iTSDeviceId: this.deviceId,
            sensorId: this.sensorId,
            displayName: this.itsSensor.displayName,
            formula: '',
            iTSDeviceName: this.itsSensor.iTSDeviceName,
            sensorDisplayName: this.itsSensor.sensorDisplayName
        };

        this.swalConfirm(this.t('AreYouSureToDelete'), true, this.t('Delete')).then((result) => {
            if (result.value) {
                this.authService.put(`/api/itssensors/`, itsSensor).then(async (response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.t('Successful'));
                        this.$emit('completed', true);
                    }
                });
            }
        });
    }
    
    save() {
        if ((this.$refs.form as Vue & { validate: () => boolean }) && this.fields[this.fields.length - 1].type === FieldType.Number) {
            this.errors = [];

            const itsSensor: ITSSensor = {
                iTSDeviceId: this.deviceId,
                sensorId: this.sensorId,
                displayName: this.itsSensor.displayName,
                formula: this.itsSensor.formula,
                iTSDeviceName: this.itsSensor.iTSDeviceName,
                sensorDisplayName: this.itsSensor.sensorDisplayName
            };

            this.authService.postOrPut(`/api/itssensors/`, itsSensor, this.deviceId)
                .then((response) => {
                if (!response.isError) {
                    this.swalToast(2000, 'success', this.t('Successful'));
                    this.$emit('completed', true);
                } else {
                    response.errors.forEach(e => this.errors.push(e));
                }
            });
        }
        else {
            this.errors= [{
                value: this.t('LastFieldValueError'),
                name: "LastFieldValueError"
            }];
        }
    }
}

